(function($) {
    $(document).ready(function() {

        function recalc() {
            let length = 0;
            try {
                length = parseInt($('#length').val());
            } catch {}

            let width = 0;
            try {
                width = parseFloat($('#width').val());
            } catch {}

            let thickness = 0;
            try {
                thickness = parseFloat($('#thickness').val());
            } catch {}

            let count = 0;
            try {
                count = parseInt($('#count').val());
            } catch {}

            let price = 0;
            try {
                price = parseFloat($('#price').val());
            } catch {}

            const volume_unit = (((width * thickness) / 100) * length) / 10000;
            const volume_all = volume_unit * count;
            const countm3 = 1 / volume_unit;
            const sumall = volume_unit * count * price;
            const sum_unit = price * volume_unit;


            $('#volume_unit').html(volume_unit ? volume_unit.toFixed(5) + ' м3' : '');
            $('#volume_all').html(volume_all ? volume_all.toFixed(5) + ' м3' : '');
            $('#countm3').html(countm3 ? countm3.toFixed(2) + ' шт.' : '');
            $('#sumall').html(sumall ? sumall.toFixed(2) + ' руб.' : '');
            $('#sum_unit').html(sum_unit ? sum_unit.toFixed(2) + ' руб.' : '');

        }

        $('#length').change(recalc);
        $('#width').change(recalc);
        $('#thickness').change(recalc);
        $('#count').change(recalc);
        $('#price').change(recalc);



    });
})(jQuery);